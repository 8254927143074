.custom-bullet-list {
    list-style: none;
    padding: 0;
}

.custom-bullet-list li {
    position: relative;
    padding-left: 20px;
    text-indent: 0px;
    margin-bottom: 10px;
}

.custom-bullet-list li:before {
    content: "\2022"; /* Unicode for bullet point */
    position: absolute;
    left: 0;
    top: 0;
}

@media (hover: hover) {
    .uiButton:hover {
        background-color: black;
        color: white;
        transform: translateY(-0.25rem);
    }
}
